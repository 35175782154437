@media (max-width: 78em) {
  .hero-img {
    width: 450px;
  }

  .hero-cta:link,
  .hero-cta:visited {
    padding: 1.2rem 2.4rem;
    font-size: 2.4rem;
  }

  .cta-consult p {
    font-size: 4.4rem;
  }

  .card {
    height: 27rem;
  }

  .face-1 {
    height: 27rem;
  }

  .services-container {
    gap: 2.4rem;
    row-gap: 5.2rem;
  }
  .service {
    height: 50%;
  }
  .card:hover .face-2 {
    transform: translateY(-50%);
  }

  .additional-services-card {
    width: 25rem;
  }
}

@media (max-width: 69em) {
  .nav {
    padding: 0 2.4rem;
  }

  .nav-items {
    gap: 3rem;
  }
  .nav-item:link,
  .nav-item:visited {
    letter-spacing: normal;
  }
}

@media (max-width: 62em) {
  .hero-img {
    width: 360px;
  }

  .hero-cta:link,
  .hero-cta:visited {
    font-size: 2rem;
    padding: 1rem 1.2rem;
  }

  .hero {
    padding: 4.4rem 0 4.4rem 1rem;
  }

  .services-container {
    padding: 0 1rem 9.6rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .service-text {
    position: relative;
  }

  .face-1::after {
    content: "More info";
    background-color: #ccf2fc;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border-radius: 11px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
  }
  .additional-services-card {
    width: 20rem;
  }

  .rating {
    padding-bottom: 4.8rem;
  }

  .container {
    flex-direction: column;
  }

  .bar-container {
    flex-direction: column;
    width: 90%;
    height: 50%;
  }

  .num-container {
    flex-direction: column;
    height: auto;
    width: 95%;
    align-items: flex-start;
  }

  .bar-background {
    width: 95%;
    height: 1.5rem;
  }

  .bar-filler-1 {
    width: var(--anim1-value);
    height: 100%;
  }
  .bar-filler-2 {
    width: var(--anim2-value);
    height: 100%;
  }
  .bar-filler-3 {
    width: var(--anim3-value);
    height: 100%;
  }

  .bar-text {
    padding-top: 1rem;
  }

  .bar-num-1 {
    padding-left: var(--anim1-value);
    height: auto;
  }
  .bar-num-2 {
    padding-left: var(--anim2-value);
    height: auto;
  }
  .bar-num-3 {
    padding-left: var(--anim3-value);
    height: auto;
  }

  @keyframes slideIn-1 {
    from {
      width: 0;
      padding-left: 0;
    }
    to {
      width: var(--anim1-value);
      padding-left: var(--anim1-value);
    }
  }

  @keyframes slideIn-2 {
    from {
      width: 0;
      padding-left: 0;
    }
    to {
      width: var(--anim2-value);
      padding-left: var(--anim2-value);
    }
  }

  @keyframes slideIn-3 {
    from {
      width: 0;
      padding-left: 0;
    }
    to {
      width: var(--anim3-value);
      padding-left: var(--anim3-value);
    }
  }
  .graph-img {
    display: none;
  }

  .small-text {
    bottom: auto;
    left: 0;
    padding-top: 8rem;
  }

  .contact-img {
    display: none;
  }

  .contact-us {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 60em) {
  .logo {
    height: 5.4rem;
  }

  .btn-mobile-nav {
    border: none;
    background: none;
    cursor: pointer;
    display: none;
    padding-right: 1.2rem;
  }

  .icon-mobile-nav[name="close-outline"] {
    display: none;
  }

  .nav-item:link,
  .nav-item:visited {
    letter-spacing: normal;
  }

  .nav-items {
    display: none;
  }

  .btn-mobile-nav {
    display: block;
    z-index: 9999;
  }
  .icon-mobile-nav {
    height: 4.8rem;
    width: 4.8rem;
  }
  .nav {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transition: all 0.5s ease-out;

    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  .nav-open .nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }
  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .nav-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4.8rem;
  }

  .nav-item:link,
  .nav-item:visited {
    font-size: 3rem;
  }
}

@media (max-width: 47em) {
  .nav {
    padding: 0 1.2rem;
  }

  .hero {
    grid-template-columns: 1fr;
    gap: 1.6rem;
    padding: 0;
    padding-bottom: 4.8rem;
  }
  .cta-consult p {
    text-align: center;
  }
  .hero-cta:link,
  .hero-cta:visited {
    display: flex;
    justify-content: center;
    font-size: 2.4rem;
    padding: 1rem;
  }
  .services-container {
    grid-template-columns: 1fr;
  }

  .additional-services {
    display: grid;
    gap: 1rem;
  }
  .additional-services-card {
    width: 25rem;
  }

  .caption {
    font-size: 3rem;
  }

  h2 {
    padding: 2.4rem 1rem;
    text-align: center;
  }

  .facts-container {
    display: grid;
  }

  .form {
    grid-template-columns: 1fr;
  }

  .bar-container {
    height: 80%;
  }

  .contact-us {
    padding: 1.2rem;
  }

  .contact-us-container {
    padding-top: 4.8rem;
  }

  .copyright {
    line-height: 1.4;
  }
}

@media (max-width: 23em) {
  .logo {
    height: 4.8rem;
    padding-left: 1.2rem;
  }
  .hero-cta:link,
  .hero-cta:visited {
    text-align: center;
  }
  .face-1::after {
    transform: translate(-50%, -25%);
  }

  .form input,
  .form select {
    font-size: 1.4rem;
  }
}
